<template>
  <div>
    <h4>Collection</h4>

    <div class="create row d-flex align-items-center">
      <div class="col-4">
        <input type="text" class="form-control" placeholder="Name" v-model="name" />
      </div>
      <div class="col-4">
        <input type="text" class="form-control" placeholder="Slug" v-model="slug" />
      </div>
      <div class="col-1">
        <input type="checkbox" class="form-check-input" id="checkbox" v-model="published" />
        <label for="checkbox" class="ms-2">published</label>
      </div>
      <div class="col-2">
        <button class="vave-btn btn-green" @click="updateCollection()">Update</button>
      </div>
    </div>

    <div class="products-input row d-flex align-items-end">
      <div class="col-6">
        <products-input v-if="productsReady" v-model="products" type="name" key="products" @ready="enableSync()" />
      </div>
      <div class="col-2">
        <button class="vave-btn btn-green" @click="pushProducts()" :disabled="!productsInputReady">Sync</button>
      </div>
    </div>

    <div class="products-table">
      <table class="table">
        <thead>
          <th>id</th>
          <th>name</th>
          <th>sku</th>
        </thead>
        <tbody>
          <tr v-for="product,k in products" :key="k" :class="{'synched': !productsSynched.includes(product.id)}">
            <td>{{product.id}}</td>
            <td>{{product.name}}</td>
            <td>{{product.sku}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";
import ProductsInput from "./widgets/helpers/Products";

export default {
  data() {
    return {
      productsReady: false,
      name: "",
      slug: "",
      productsInputReady: false,
      published: true,
      collection: [],
      products: [],
      productsSynched: [],
    };
  },
  components: { ProductsInput },
  mounted() {
    this.getCollection();
  },
  methods: {
    getCollection() {
      axios
        .get("/api/admin/collections/" + this.$route.params.id)
        .then((response) => {
          if (response.data.data) {
            let res = response.data.data;
            this.collection = response.data.data;
            this.name = res.name;
            this.slug = res.slug;
            this.published = res.published;
          }
        });

      axios
        .get("/api/admin/collections/" + this.$route.params.id + "/products")
        .then((response) => {
          if (response.data.data) {
            let products = response.data.data;
            var p = [];
            var p2 = [];
            products.forEach((product) => {
              p.push({
                id: product.id,
                name: product.name,
                slug: product.slug,
                sku: product.sku,
              });
              p2.push(product.id);
            });
            this.products = p;
            this.productsSynched = p2;
            this.productsReady = true;
          }
        });
    },
    enableSync() {
      this.productsInputReady = true;
    },
    updateCollection() {
      if (
        !confirm(
          'do you want to update the collection with name "' +
            this.name +
            '" and slug "' +
            this.slug +
            '"?'
        )
      ) {
        return;
      }
      axios
        .put("/api/admin/collections/" + this.$route.params.id, {
          name: this.name,
          slug: this.slug,
          published: this.published,
        })
        .then(
          () => {
            this.getCollection();
          },
          (e) => {
            console.log(e.response.data);
            alert(e.response.data.message);
          }
        );
    },
    pushProducts() {
      axios
        .put("/api/admin/collections/" + this.$route.params.id + "/products", {
          products: this.products,
        })
        .then(
          () => {
            this.getCollection();
          },
          (e) => {
            console.log(e.response.data);
            alert(e.response.data.message);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
.products-input {
  margin-bottom: 20px;
}
.products-table {
  background: white;
  padding: 20px;
  tbody tr {
    &:hover {
      background: #fafafa;
    }
  }
}
.create {
  margin: 20px 0px;
  padding-bottom: 20px;
}
.synched {
  background: hsl(42, 100%, 71%);
}
</style>